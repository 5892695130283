







































import {
  Prop, Vue, Component, Watch,
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import DoubtsService from '@/services/Doubts/DoubtsService';

import { LIST_FEEDBACKS, BUTTONS } from './constants';

@Component({
  components: {
    Modal,
  },
})
export default class FeedbackModal extends Vue {
  @Prop() dispatchResponse!: string;

  private listFeedbacks: Record<string, any> = {};
  private DoubtsService = new DoubtsService();

  @Watch('dispatchResponse')
  setFeedback() {
    const routes = this.setRoutesFromFeedback();
    const routerFrom = routes.from.name;
    const routerTo = routes.to.name;

    if (this.dispatchResponse && this.dispatchResponse === 'success') {
      if (routerFrom !== routerTo) {
        if (routerTo === 'NewDoubt') {
          this.listFeedbacks = LIST_FEEDBACKS.success.NewDoubt.Doubts;
        } else if (routerTo === 'videos') {
          this.listFeedbacks = LIST_FEEDBACKS.success.videos;
        } else if (routerFrom === 'NewDoubt' && routerTo === 'ShowDobts') {
          this.listFeedbacks = LIST_FEEDBACKS.success.ShowDobts.default;
        } else {
          this.listFeedbacks = LIST_FEEDBACKS.success[routerTo][routerFrom];
        }
      } else if (routerTo === 'videos') {
        this.listFeedbacks = LIST_FEEDBACKS.success.videos;
      } else if (routerTo === 'NewDoubt') {
        this.listFeedbacks = LIST_FEEDBACKS.success.NewDoubt.Doubts;
      } else {
        this.listFeedbacks = LIST_FEEDBACKS.success[routerTo].default;
      }
    } else if (this.dispatchResponse && this.dispatchResponse === 'failure') {
      this.listFeedbacks = LIST_FEEDBACKS.failure[routerTo];
    }
  }

  setRoutesFromFeedback() {
    const { from, to } = this.$store.getters['RouterHistory/routesFromTo'];
    return { from, to };
  }

  async redirect(textButton: string) {
    if (textButton === BUTTONS.SHOW_DOUBT) {
      await this.showLastDoubt();

    } else if (textButton !== BUTTONS.CONTINUE && textButton !== BUTTONS.CLOSE && textButton !== BUTTONS.TRY_AGAIN) {
      const routes = this.setRoutesFromFeedback();
      const routerFrom = routes.from.fullPath;
      const routerTo = routes.to.fullPath;

      if (routerFrom !== routerTo) {
        this.$router.push(routerFrom);
      } else {
        this.$router.push({
          name: 'Doubts',
        });
      }
    }
  }

  async showLastDoubt() {
    try {
      const { doubts } = await this.DoubtsService.getMyDoubts({
        limit: 1,
      });

      if (doubts && doubts.length) {
        const { ID } = doubts[0];

        this.$router.push({
          name: 'ShowDoubts',
          params: {
            id: ID,
          },
        });
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar a dúvida.',
        status: 'error',
      });
      console.error(error);
    }

  }
}
