export const BUTTONS = {
  CLOSE: 'Fechar',
  TRY_AGAIN: 'Tentar novamente',
  CONTINUE: 'Continuar em Aulas',
  SHOW_DOUBT: 'Visualizar minha Dúvida',
};

export const LIST_FEEDBACKS: Record<string, any> = {
  success: {
    ShowDoubts: {
      Doubts: {
        img: 'woohooo.svg',
        text: 'Sua resposta foi enviada com sucesso.',
        buttonLeft: 'Voltar para Dúvidas',
        buttonRight: BUTTONS.CLOSE,
        marginTop: 13,
        width: 172.73,
        height: 32.63,
      },
      videos: {
        img: 'woohooo.svg',
        text: 'Sua resposta foi enviada com sucesso.',
        buttonLeft: 'Voltar para Aulas',
        buttonRight: BUTTONS.CLOSE,
        marginTop: 13,
        width: 172.73,
        height: 32.63,
      },
      NewDoubt: {
        img: 'woohooo.svg',
        text: 'Sua resposta foi enviada com sucesso.',
        buttonRight: BUTTONS.CLOSE,
        marginTop: 13,
        width: 172.73,
        height: 32.63,
      },
      default: {
        img: 'woohooo.svg',
        text: 'Sua resposta foi enviada com sucesso.',
        buttonRight: BUTTONS.CLOSE,
        marginTop: 13,
        width: 172.73,
        height: 32.63,
      },
    },
    NewDoubt: {
      Doubts: {
        img: 'woohooo.svg',
        text: 'Sua dúvida foi enviada com sucesso.',
        buttonLeft: 'Voltar para Dúvidas',
        buttonRight: BUTTONS.SHOW_DOUBT,
        marginTop: 13,
        width: 172.73,
        height: 32.63,
      },
    },
    videos: {
      img: 'woohooo.svg',
      text: 'Sua dúvida foi enviada com sucesso.',
      buttonLeft: BUTTONS.CONTINUE,
      buttonRight: BUTTONS.SHOW_DOUBT,
      marginTop: 13,
      width: 172.73,
      height: 32.63,
    },
  },
  failure: {

    ShowDoubts: {
      img: 'Ooops.svg',
      text: 'Algo deu errado! Não foi possível realizar o envio da sua resposta. Tente novamente.',
      buttonRight: BUTTONS.TRY_AGAIN,
      marginTop: 29,
      width: 133.97,
      height: 38.81,
    },
    NewDoubt: {
      img: 'Ooops.svg',
      text: 'Algo deu errado! Não foi possível realizar o envio da sua dúvida. Tente novamente.',
      buttonRight: BUTTONS.TRY_AGAIN,
      marginTop: 29,
      width: 133.97,
      height: 38.81,
    },
    videos: {
      img: 'Ooops.svg',
      text: 'Algo deu errado! Não foi possível realizar o envio da sua dúvida. Tente novamente.',
      buttonRight: BUTTONS.TRY_AGAIN,
      marginTop: 29,
      width: 133.97,
      height: 38.81,
    },
  },
};
